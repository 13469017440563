import React, { useState, useEffect } from "react";
import CreateDirectoryModal from "./CreateDirectoryModal";
import FileUpload from "./FileUpload";
import DeleteElementModal from "./DeleteElementModal";

const CreateModal = ({ active, handleModal, modalType, context, elementId, item, currentElement, setLoadingState }) => {

    const [fileList, setFileList] = useState([]);

    const [errorMessage, setErrorMessage] = useState("");

    const [dirName, setDirName] = useState("");


    const {token} = context;


    useEffect(() => {
        if (!active) {
            cleanModalFormData();
        }
    });

    const uploadFilesWithProgress = (files, keys, token) => {
        const uploadPromises = [];
        // const filesTotal = files.length;
        // let currentFile = 0;

        for (const file of files) {
            // currentFile += 1;
            const formData = new FormData();
            formData.append("file_obj", file, file.name);
            formData.append("keys", JSON.stringify(keys));

            const xhr = new XMLHttpRequest();
            xhr.open("POST", "api/upload_file");
            xhr.setRequestHeader("Authorization", "Bearer " + token);

            // xhr.upload.addEventListener("progress", (event) => {
            //     if ( event.lengthComputable ) {
            //         let totalSize = event.total;

            //         if (!totalSize && xhr.getResponseHeader("Content-Length")) {
            //             totalSize = parseInt(xhr.getResponseHeader("Content-Length"));
            //         }
            //         const progressPercent = Math.round((event.loaded / totalSize) * 100);
            //         console.log(event.loaded);
            //         console.log(totalSize);
            //         console.log(`Progress: ${progressPercent}`)
            //     }
            // });

            xhr.onload = (() => {
                // const fileIndex = currentFile;
                // setUploading(true);
                return () => {
                    if (xhr.status === 200) {
                        console.log(JSON.parse(xhr.responseText));
                        // setProgress(`Uploading... ${fileIndex}/${filesTotal}`);
                    }
                }
            })();

            xhr.onerror = () => {
                console.error("Error uploading file:", file.name);
                setErrorMessage("The error occurred while uploading file", file.name);
            };

            xhr.send(formData);

            const uploadPromise = new Promise((resolve, reject) => {
                xhr.onloadend = () => {
                    if (xhr.status === 200) {
                        resolve(JSON.parse(xhr.responseText));
                    } else {
                        reject(new Error("The error occurred while uploading file"));
                    }
                };
            });

            uploadPromises.push(uploadPromise);
        }
        return Promise.all(uploadPromises);
    }

    const handleUploadFile = async (e) => {
        setLoadingState(true);
        // const formData = new FormData();
        let keys = getKeys(currentElement, "File Storage").reverse();
        uploadFilesWithProgress(files, keys, token)
        .then((responseDatas) => {
            handleModal(responseDatas);
            setLoadingState(false);
            // setProgress("");
            // setUploading(false);
        })
        .catch((error) => {
            console.error(error);
            setErrorMessage("The error occurred while uploading file");
        });
    };

    const handleCreateDirectory = async (e) => {
        // e.preventDefault();

        let keys = getKeys(currentElement, "File Storage");
        if (! Array.isArray(keys)) {
            setErrorMessage(keys);
            return;
        }
        setLoadingState(true);

        const requestOptions = {
            // method: "POST",
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                action: "create",
                keys: keys.reverse(),
                key: dirName,
            }),
        };

        // const response = await fetch("/storage/api/storage_directory", requestOptions);
        const response = await fetch("/api/storage_elements/", requestOptions);
        const responseData = await response.json();

        setLoadingState(false);

        if (!response.ok) {
            console.log("THE ERROR");
            console.log(response);
            setErrorMessage(responseData.message);
        } else {
            cleanModalFormData();
            handleModal(responseData);
            // return responseData;
        }
    };

    const cleanModalFormData = () => {
        setDirName("");
        setErrorMessage("");
    };


    const getKeys = (element, parentSelector) => {

        if (element.node.text === parentSelector) {
            if (!/^[a-zA-Z0-9.\-_]{1,255}$/.test(dirName)) {
                return "The bucket name shouldn't contain any whitespaces or special symbols.";
            }
            return [];
        } 

        let keys = [];
        keys.push(element.node.text);

        let p = element.node.parent;

        while (p.text !== parentSelector) {
            let o = p;
            keys.push(o.text);
            p = o.parent;
        }

        return keys;
    }

    const handleDeleteElement = async (e) => {
        let keys = getKeys(currentElement, "File Storage").reverse();
        setLoadingState(true);
        const requestOptions = {
            // method: "DELETE",
            method: "PATCH",
            body: JSON.stringify({
                action: "delete",
                keys: keys
            }),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        };

        console.log(keys);

        // const response = await fetch(`/storage/api//storage_elements/?element_id=${elementId}`, requestOptions);
        const response = await fetch(`/api/storage_elements/`, requestOptions);
        const responseData = await response.json();

        setLoadingState(false);
        if (!response.ok) {
            console.log("THE ERROR");
            console.log(response);
            setErrorMessage("The error occurred while deleting directory");
        } else {
            cleanModalFormData();
            handleModal(responseData);
        }
    };

    const files = fileList ? [...fileList] : [];

    return (
        <div className={`modal ${active && "is-active"}`}>
            <div className="modal-background" onClick={(e) => handleModal()}></div>
            <div className="modal-card">
                {modalType && modalType === "directory" && (
                    <CreateDirectoryModal
                        handleModal={handleModal}
                        handleCreateDirectory={(e) => handleCreateDirectory(e)}
                        dirName={dirName}
                        setDirName={setDirName}
                        errorMessage={errorMessage}
                    />
                )}
                {modalType && modalType === "element" && (
                    <FileUpload 
                    errorMessage={errorMessage}
                    handleModal={handleModal}
                    handleUploadFile={handleUploadFile}
                    setFileList={setFileList}
                    active={active}
                    />
                )}
                {modalType && modalType === "delete" && (
                    <DeleteElementModal
                        item={item}
                        errorMessage={errorMessage}
                        handleModal={handleModal}
                        handleDeleteElement={handleDeleteElement}
                    />
                )}
            </div>
        </div>
    );
};

export default CreateModal;
