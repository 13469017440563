import React, {useContext } from "react";
import { Routes, Route } from "react-router-dom";

import { RoleContext } from "../../context/UserContext";
import MyTreeView from "../MyTreeView";
import UserPanel from "./UserPanel";


const AdminRoutes = () => {
    const role = useContext(RoleContext);
    return (
        <Routes>
            <Route path="/" element={<MyTreeView userRole={role}/>} />
            <Route path="/user-panel" element={<UserPanel />} />
        </Routes>
    );
};

export default AdminRoutes;
