import React, { useState, useEffect, useContext } from 'react'
import {UserContext, RoleContext} from '../../context/UserContext';
import ChangePasswordModal from './ChangPasswordModal';
import CreateUserModal from './CreateUserModal';
import DeleteUserModal from './DeleteUserModal';
import { useNavigate } from 'react-router-dom';

export const availableRoles = ["admin", "manager", "designer"];


const UserPanel = () => {

    const {token, currentUsername} = useContext(UserContext);

    const role = useContext(RoleContext);

    const navigate = useNavigate();

    const [listUsers, setListUsers] = useState([]);

    const [userModalActive, setUserModalActive] = useState(false);

    const [changeModalActive, setChangeModalActive] = useState(false);

    const [deleteModalActive, setDeleteModalActive] = useState(false);

    const [refreshUserList, setRefreshUserList] = useState(false);

    const [username, setUsername] = useState("");

    const handleUserModal = () => {
        setUserModalActive(!userModalActive);
    }

    const handleChangeModal = () => {
        setChangeModalActive(!changeModalActive);
    }

    const handleDeliteModal = () => {
        setDeleteModalActive(!deleteModalActive);
    }

    const openChangeModal = (email) => {
        setChangeModalActive(true);
        setUsername(email);
    }

    const openDeleteModal = (email) => {
        setDeleteModalActive(true);
        setUsername(email);
    }

    const handleRoleChange = async (event, user) => {
    
        const requestOptions = {
            method: "PATCH",
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + token,
            },
            body: JSON.stringify({
                role: event.target.value
            }),
        };

        const response = await fetch(`/api/users/${user.email}`, requestOptions);

        const data = await response.json();
        if (!response.ok) {
            alert(data.detail);
        } else {
            setRefreshUserList(!refreshUserList);
        }
    }


    useEffect(() => {
        console.log(role)
        if (role !== "admin" && role !== "") {
            navigate("/")
        }
        const fetchUsers = async () => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            };

            const response = await fetch("/api/users", requestOptions);
            const responseData = await response.json();

            if (!response.ok) {
                console.log("THE ERROR!")
                console.log(response)
            } else {
                setListUsers(responseData.users);
            };
        };
        fetchUsers();
    }, [refreshUserList, navigate, role, token])

    return (
        <div>
            <CreateUserModal
                active={userModalActive}
                handleModal={handleUserModal}
                setRefreshUserList={setRefreshUserList}
                refreshUserListState={refreshUserList}
            />
            <ChangePasswordModal 
                active={changeModalActive}
                username={username}
                handleModal={handleChangeModal}
            />
            <DeleteUserModal 
                active={deleteModalActive}
                username={username}
                setRefreshUserList={setRefreshUserList}
                handleModal={handleDeliteModal}
                refreshUserListState={refreshUserList}
            />
            <div>
                <button
                    id="user-pannel-btn"
                    className="button mb-2 mr-2 is-info is-light"
                    onClick={(event) => {navigate("/")}}
                > Back </button>
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Delete</th>
                        <th>Change password</th>
                    </tr>
                </thead>
                <tbody>
                    {listUsers.map(user => {
                        return (
                            <tr key={user.email}>
                                <td>{user.email}</td>
                                <td>
                                    <select value={user.role} disabled={user.role === "admin"} onChange={(event) => handleRoleChange(event, user)}>
                                        {availableRoles.map((role) => (
                                        <option key={role} value={role}>
                                            {role}
                                        </option>
                                        ))}
                                    </select>
                                </td>
                                <td><button className="button is-danger is-small" onClick={(e) => openDeleteModal(user.email)} disabled={user.email === currentUsername}>DEL</button></td>
                                <td><button className="button is-info is-small" onClick={(e) => openChangeModal(user.email)}>CHANGE</button></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div>
                <button id="crete-new-user" className="button mb-2 mr-2 is-info is-light" onClick={(e) => setUserModalActive(true)}> 
                    Create new user
                </button>
            </div>
        </div>
    )
}

export default UserPanel;