import { useState, useEffect } from "react";

const PasswordInput = ( props ) => {


    const {newModal, setNewModal} = props;

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [validPassword, setValidPassword] = useState(true);
    const [validConfirmPassword, setValidConfirmPassword] = useState(true);

    const togglePassword = () => {
        setShowPassword(!showPassword);
    }

    const toggleConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    const handlePasswordChange = (event) => {
        props.setPassword(event.target.value);
    }

    const handleConfirmPasswordChange = (event) => {
        props.setConfirmPassword(event.target.value);
    }

    const validatePassword = () => {
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[-+_!@#$%^&*.,?])[0-9a-zA-Z-+_!@#$%^&*.,?]{8,}$/;
        const isValid = passwordRegex.test(props.password);
        setValidPassword(isValid);
    }

    const validateConfirmPassword = () => {
        setValidConfirmPassword(props.password === props.confirmPassword)
    }

    useEffect(() => {
        if (newModal) {
            setValidPassword(true);
            setValidConfirmPassword(true);
            setNewModal(!newModal);
        }
    }, [newModal, setNewModal])


    return (
        <>
            <div className="field">
                <div className="control" style={{position: 'relative'}}>
                    <label className="label">Password</label>
                    <div style={{position: 'relative'}}>
                        <input style={{paddingRight: '40px'}} type={showPassword ? 'text' : 'password'} className={`input ${validPassword ? '': 'is-danger'}`} value={props.password} onChange={handlePasswordChange} onKeyUp={validatePassword} />
                        <span style={{position: "absolute", top: "50%", right: "10px", transform: "translateY(-50%)", cursor: "pointer"}} className="password-toggle-icon" onClick={togglePassword}>
                            {showPassword ? <i className="fa fa-eye-slash"></i> : <i className="fa fa-eye"></i>}
                        </span>
                    </div>
                    {!validPassword && (<div className="has-text-centered has-text-weight-bold has-text-danger">Invalid password. Password should contains at least 1 digit, 1 lowercase letter, 1 uppercase letter, 1 of the special symbols [-+_!@#$%^&*.,?] and have a length of at least 8 characters</div>)}
                </div>
            </div>
            <div className="field">
                <div className="control" style={{position: 'relative'}}>
                    <label className="label">Confirm password</label>
                    <div style={{position: 'relative'}}>
                        <input style={{paddingRight: '40px'}} type={showConfirmPassword ? 'text' : 'password'} className={`input ${validConfirmPassword ? '': 'is-danger'}`} value={props.confirmPassword} onChange={handleConfirmPasswordChange} onKeyUp={validateConfirmPassword} />
                        <span style={{position: "absolute", top: "50%", right: "10px", transform: "translateY(-50%)", cursor: "pointer"}} className="password-toggle-icon" onClick={toggleConfirmPassword}>
                            {showConfirmPassword ? <i className="fa fa-eye-slash"></i> : <i className="fa fa-eye"></i>}
                        </span>
                    </div>
                    {!validConfirmPassword && (<div className="has-text-centered has-text-weight-bold has-text-danger">Passwords don't match</div>)}
                </div>
            </div>
        </>
    )
}

export default PasswordInput;