import ErrorMessage from "./ErrorMessage";

const DeleteElementModal = ({ item, errorMessage, handleModal, handleDeleteElement }) => {
    return (
        <>
            <header className="modal-card-head has-background-primary-light">
                <h1 className="modal-card-title">Delete element</h1>
            </header>
            <section className="modal-card-body">
                <div className="field">
                    <div className="control">
                        <p>Do you really want to delete this element?</p>
                        <p>{item.text}</p>
                    </div>
                </div>
            </section>
            <ErrorMessage message={errorMessage} />
            <footer className="modal-card-foot has-background-primary-light">
                <button className="button is-info createElementBtn" onClick={(e) => handleDeleteElement()}>
                    Delete
                </button>
                <button className="button" style={{ cursor: "pointer" }} onClick={(e) => handleModal()}>
                    Cancel
                </button>
            </footer>
        </>
    );
};

export default DeleteElementModal;
