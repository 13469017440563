import React, { useContext } from "react";

import Login from "./components/Login";
import Header from "./components/Header";
import MyTreeView from "./components/MyTreeView";
import { UserContext } from "./context/UserContext";
import AdminRoutes from "./components/admin/AdminRoutes";

const App = () => {

    const {token} = useContext(UserContext);

    return (
            <>
                <Header title="File Storage" />
                <div className="columns">
                    {!token ? (
                        <div className="column is-half is-offset-one-quarter"><Login /></div>

                    ): (
                        <>
                            <div className="column is-1"></div>
                            <div className="column m-5 is-two-thirds">
                                <AdminRoutes />
                            </div>
                        </>
                    )}
                </div>
            </>
    );
};

export default App;
