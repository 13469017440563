import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import ErrorMessage from "../ErrorMessage";

const DeleteUserModal = ( props ) => {

    const {token} = useContext(UserContext);

    const [errorMessage, setErrorMessage] = useState("");

    const deleteUser = async () => {
        const requestOptions = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            },
        };
        const response = await fetch(`/api/users/${props.username}`, requestOptions);

        const data = await response.json();

        if (!response.ok) {
            setErrorMessage(data.detail);
        } else {
            props.setRefreshUserList(!props.refreshUserListState);
            props.handleModal();
        }
    }

    return (
        <div className={`modal ${props.active && "is-active"}`}>
            <div className="modal-background" onClick={(e) => props.handleModal()}></div>
            <div className="modal-card">
                <header className="modal-card-header has-background-primary-light">
                    <h1 className="modal-card-title">Delete user</h1>
                </header>
                <section className="modal-card-body">
                    <div className="field">
                        <div className="control">
                            Delete user {props.username}?
                        </div>
                    </div>
                </section>
                <ErrorMessage message={errorMessage} />
            <footer className="modal-card-foot has-background-primary-light">
                <button className="button is-info" onClick={(e) => deleteUser()}>
                    Delete
                </button>
                <button className="button is-danger" style={{ cursor: "pointer" }} onClick={(e) => props.handleModal()}>
                    Cancel
                </button>
            </footer>
            </div>
        </div>
    )
}

export default DeleteUserModal;