import React, { useState, useContext } from "react";

import ErrorMessage from "./ErrorMessage";
import { UserContext } from "../context/UserContext";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [errorMessage, setErrorMessage] = useState("");

    const [showPassword, setShowPassword] = useState(false);

    const toggleConfirmPassword = () => {
        setShowPassword(!showPassword);
    }

    const {setToken} = useContext(UserContext);

    const submitLogin = async () => {
        const passwordEncoded = encodeURIComponent(password);
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: JSON.stringify(`grant_type=&username=${email}&password=${passwordEncoded}&scope=&client_id=&client_secret=`),
        };

        const response = await fetch("/api/token", requestOptions);

        const data = await response.json();

        if (!response.ok) {
            setErrorMessage(data.detail);
        } else {
            setToken(data.access_token);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        submitLogin();
    };

    return (
        <div className="column">
            <form className="box" onSubmit={handleSubmit}>
                <h1 className="title has-text-centered">Login</h1>
                <div className="field">
                    <label className="label">Email address</label>
                    <div className="control">
                        <input
                            className="input"
                            required
                            type="email"
                            placeholder="Enter Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                </div>
                <div className="field">
                    <div className="control" style={{position: 'relative'}}>
                        <label className="label">Password</label>
                        <div style={{position: 'relative'}}>
                            <input
                                className="input"
                                required
                                type={showPassword ? "text" : "password"}
                                placeholder="Enter password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <span style={{position: "absolute", top: "50%", right: "10px", transform: "translateY(-50%)", cursor: "pointer"}} className="password-toggle-icon" onClick={toggleConfirmPassword}>
                            {showPassword ? <i className="fa fa-eye-slash"></i> : <i className="fa fa-eye"></i>}
                        </span>
                        </div>
                    </div>
                </div>
                <ErrorMessage message={errorMessage} />
                <br />
                <button className="button is-primary" type="submit">
                    Login
                </button>
            </form>
        </div>
    );
};

export default Login;
