import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import ErrorMessage from "../ErrorMessage";
import PasswordInput from "./PasswordInput";

const ChangePasswordModal = ( props ) => {

    const {token} = useContext(UserContext);


    const [errorMessage, setErrorMessage] = useState("");

    const [newModal, setNewModal] = useState(true);

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const cancelChangePassword = () => {
        setPassword("");
        setConfirmPassword("");
        setNewModal(true);
        props.handleModal();
    }

    const changePassword = async () => {
        if (password !== "" && password === confirmPassword) {
            const requestOptions = {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token,
                },
                body: JSON.stringify(
                    {
                        email: props.username,
                        hashed_password: password,
                    }
                ),
            };
            const response = await fetch(`/api/users/${props.username}`, requestOptions);

            const data = await response.json();

            if (!response.ok) {
                setErrorMessage(data.detail);
            } else {
                cancelChangePassword();
                // setPassword("");
                // setConfirmPassword("");
                // props.handleModal();
            }

        }
    }

    return (
        <div className={`modal ${props.active && "is-active"}`}>
            <div className="modal-background" onClick={(e) => props.handleModal()}></div>
            <div className="modal-card">
                <header className="modal-card-header has-background-primary-light">
                    <h1 className="modal-card-title">Change password</h1>
                </header>
                <section className="modal-card-body">
                    <PasswordInput 
                        password={password}
                        confirmPassword={confirmPassword}
                        setPassword={setPassword}
                        setConfirmPassword={setConfirmPassword}
                        newModal={newModal}
                        setNewModal={setNewModal}
                    />
                </section>
                <ErrorMessage message={errorMessage} />
            <footer className="modal-card-foot has-background-primary-light">
                <button className="button is-info" onClick={(e) => changePassword()}>
                    Change
                </button>
                <button className="button is-danger" style={{ cursor: "pointer" }} onClick={cancelChangePassword}>
                    Cancel
                </button>
            </footer>
            </div>
        </div>
    )
}

export default ChangePasswordModal;