import React, { createContext, useState, useEffect } from "react";
import { decodeJwt } from "jose"

export const UserContext = createContext();

export const RoleContext = createContext();

export const UserProvider = (props) => {
    const [token, setToken] = useState(localStorage.getItem("someUserToken"));
    const [role, setRole] = useState("");
    const [currentUsername, setCurrentUsername] = useState("");

    useEffect(() => {
        const fetchUser = async () => {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            };
        
            const response = await fetch("/api/users/me", requestOptions);
        
            if (!response.ok) {
                setToken(null);
            } else {
                setRole(decodeJwt(token).role)
                setCurrentUsername(decodeJwt(token).email)
                localStorage.setItem("someUserToken", token);
            }
        };
        fetchUser();
    }, [token, role]);

    return (
        <UserContext.Provider value={{token, setToken, currentUsername, setCurrentUsername}}>
            <RoleContext.Provider value={role}>
                {props.children}
            </RoleContext.Provider>
        </UserContext.Provider>
    );
};
