import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import ErrorMessage from "../ErrorMessage";

import PasswordInput from "./PasswordInput";

import {availableRoles} from "./UserPanel";

const CreateUserModal = ( props ) => {

    const {token} = useContext(UserContext);

    const [errorMessage, setErrorMessage] = useState("");

    const [email, setEmail] = useState("");
    const [role, setRole] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [newModal, setNewModal] = useState(true);

    const [validEmail, setValidEmail] = useState(true);


    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const validateEmail = () => {
        const emailRegex = /^\S+@\S+\.\S+$/;
        const isValid = emailRegex.test(email);
        setValidEmail(isValid);
    }

    const cancelCreateUser = () => {
        setEmail("");
        setValidEmail(true);
        setPassword("");
        setConfirmPassword("");
        props.handleModal()
        setNewModal(true);
    }

    const createUser = async () => {

        if (email && password && password === confirmPassword) {
            const requestOptions = {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token,
                },
                body: JSON.stringify(
                    {
                        email: email,
                        hashed_password: password,
                        role: role
                    }
                ),
            };
            const response = await fetch("/api/users", requestOptions);

            const data = await response.json();

            if (!response.ok) {
                setErrorMessage(data.detail);
            } else {
                setEmail("");
                setPassword("");
                setConfirmPassword("");
                setRole("");
                props.setRefreshUserList(!props.refreshUserListState);
                props.handleModal();
            }

        } else {
            setErrorMessage("Please fill out all fields correctly")
        }
    }

    return (
        <div className={`modal ${props.active && "is-active"}`}>
            <div className="modal-background" onClick={(e) => props.handleModal()}></div>
            <div className="modal-card">
                <header className="modal-card-header has-background-primary-light">
                    <h1 className="modal-card-title">Create new user</h1>
                </header>
                <section className="modal-card-body">
                    <div className="field">
                        <div className="control">
                            <label className="label">Email</label>
                            <input type="text" className={validEmail ? "input" : "invalid input"} value={email} onKeyUp={validateEmail} onChange={handleEmailChange} />
                            {!validEmail && <div className="has-text-centered has-text-weight-bold has-text-danger">Invalid email format</div>}
                        </div>
                    </div>
                    <div className="field">
                        <div className="control">
                            <label className="label">Role</label>
                            <div className="select">
                                <select value={role} onChange={(event) => setRole(event.target.value)}>
                                            {availableRoles.map((role) => (
                                            <option key={role} value={role}>
                                                {role}
                                            </option>
                                            ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <PasswordInput 
                        password={password}
                        confirmPassword={confirmPassword}
                        setPassword={setPassword}
                        setConfirmPassword={setConfirmPassword}
                        newModal={newModal}
                        setNewModal={setNewModal}
                    />
                </section>
                <ErrorMessage message={errorMessage} />
            <footer className="modal-card-foot has-background-primary-light">
                <button className="button is-info" onClick={(e) => createUser()}>
                    Create
                </button>
                <button className="button is-danger" style={{ cursor: "pointer" }} onClick={cancelCreateUser}>
                    Cancel
                </button>
            </footer>
            </div>
        </div>
    )
}

export default CreateUserModal;